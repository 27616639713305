<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    title?: string;
    open?: boolean;
    animation?: boolean;
  }>(),
  {
    open: true,
    animation: false,
  },
);
const { open } = toRefs(props);
const emit = defineEmits(["update:open"]);

const _open = ref(open.value);
watch(open, (val) => {
  if (val !== _open.value) _open.value = val;
});

const details = ref<HTMLDetailsElement>();
function toggle() {
  if (details?.value) {
    _open.value = details?.value!.open;
    emit("update:open", details?.value!.open);
  }
}
</script>

<template>
  <div class="b-1.5 b-scheppach-primary-100">
    <details ref="details" :open="open" class="rd-b w-full" @toggle="toggle">
      <summary
        class="relative flex items-center text-scheppach-primary-500 justify-between p-3 bg-scheppach-primary-50 font-700 cursor-pointer list-none [&::-webkit-details-marker]-hidden pr-8"
      >
        <slot name="title">
          <p
            v-if="title"
            :class="{
              'line-clamp-1': !details?.open,
              'lg:hover:translate-x-[5px] duration-150 ease-in-out w-full select-none':
                animation,
            }"
          >
            {{ title }}
          </p>
        </slot>

        <div
          class="w-[15px] h-[15px] c-scheppach-primary-700 absolute top-18px right-10px"
          :class="[!_open ? 'i-sl-arrow-left-1' : 'i-sl-arrow-down-1']"
        ></div>
      </summary>
      <slot></slot>
    </details>
  </div>
</template>
